<template>
	<div class="indexUser">
		<div>
			<!-- header  点击 -->
			<headers :userList="userList"></headers>
		</div>
		<div class="service">
			<p>我的用药</p>
			<div class="explain" v-for="(item, i) in prelist" :key="i">
				<p>
					<span class="time">{{ item.add_time }}</span>
					<span class="name">由{{item.add_username}}开具给{{ item.inquiry_name }}</span>
					<span class="fr" @click="fastshop(item)">快速购药</span>
				</p>
				<div class="wrapper" v-for="(good,j) in item.goods_list" :key="j">
					<div class="title">
						<div class="good_name">{{ good.goods_name }}</div>
						<div>{{good.spec}}</div>
						<div>{{good.num}}{{good.unit}}</div>
					</div>
					<div class="content">
						<div class="fangshi">
							<span>用法: {{good.drugRoute_name}}</span>
							<span> 用量:{{good.drugFrequency_name}}/每次{{good.drugDosage}}{{good.drugUseUnit_name}}</span>
						</div>
						<div>{{ good.yongfa }}</div>
						<div>{{ good.yongliang}}</div>
					</div>
				</div>
			</div>
			<div class="ym">
				<el-pagination background layout="prev, pager, next" :total="total" page-size="15" @current-change="getlist">
				</el-pagination>
			</div>
		</div>
		<div class="choiceshop" v-show="isdoctor">
			<div class="shopbox">
				<div class="title">
					选择药店
					<div class="closebox el-icon-close" @click="isdoctor=false"></div>
				</div>
				<div class="choicecontent">
					<div class="shopboxlist"  v-for="(item,index) in shoplist" :key="index">
						<div>
							<el-radio v-model="radio" :label="index">{{item.shop_name}}</el-radio>
						</div>
						<div class="drugsbox" v-for="(value,key) in item.goods_list" :key="key">
							<img :src="value.goods_img" alt="">
							<div class="rightCot">
								<p class="drugs">{{value.goods_name}}</p>
								<p class="price">￥{{value.price}}</p>
							</div>
						</div>
					</div>
				</div>
				<div class="choicebottom">
					<div class="button" @click="submit">提交</div>
				</div>
			</div>
		</div>
		<div>
			<publicBottom></publicBottom>
		</div>
	</div>
</template>

<script>
	import headers from "./header"; // 头部 TOP 和header
	import publicBottom from "@/components/public/public_bottom"; //底部

	import {
		usergetinfo
	} from "@/request/user";

	// 获取自己添加的处方单
	import {
		getDoctorAuditList
	} from "@/request/user";



	export default {
		components: {
			headers,
			publicBottom
		},
		data() {
			return {
				prelist: "",
				userList: [], // 个人信息
				total: 0,
				page: 1,
				isdoctor: false,
				radio: -1,
				shoplist: "",
				orderinfor:"",
			};
		},
		created() {
			let datas = localStorage.getItem("lzyyToken");
			let Athis = this;
			if (datas != null) {
				this.$tokens = datas;
			} else {
				this.$message.error("登录错误，请重新登录");
				setTimeout(function() {
					Athis.$router.replace({
						path: "/land"
					});
				}, 1000);
			}
			//   个人信息
			usergetinfo({
				token: datas
			}).then(res => {
				this.userList = res.data;
			});
			this.$axios.post("/api/prescription/getList", {
				status_str: 2,
				page: 1,
				token: this.$token
			}).then(res => {
				this.total = res.data.data.total;
				console.log(this.total);
				if (res.data.code !== 1) return;
				if (res.data.data.data.length == 0) {
					this.$message({
						type: "error",
						message: "暂无药师开具药方"
					})
				}
				this.prelist = res.data.data.data;
			});
		},
		methods: {
			fastshop(item) {
				console.log(item);
				this.orderinfor=item;
				if (item.type == 1) {
					for (let value of item.goods_list) {
						this.$axios.post("/api/goods_cart/prescriptionToCart", {
							p_code: value.p_code,
							shop_id: value.shop_id,
							token: this.$token
						}).then(res => {
							if (res.data.code == 1) {
								this.$message.success("操作成功,该商品已加入购物车");
							}
						})
					}
				} else {
					this.getshop(item);
				}
			},
			// 获取药店列表
			getshop(item) {
				this.$axios.post("/api/shop/selectShop", {
					p_code: item.p_code,
					token: this.$token,
				}).then(res => {
					if (res.data.code == 1) {
						this.shoplist = res.data.data;
						console.log(this.shoplist);
						if (this.shoplist.length == 0) {
							this.$message.error("未找到相关药店");
						} else {
							this.isdoctor = true;
						}
					}
				});
			},
			getlist(v) {
				this.$axios.post("/api/prescription/getList", {
					tatus_str: 2,
					page: v,
					token: this.$token
				}).then(res => {
					this.total = res.data.data.total;
					if (res.data.code !== 1) return;
					if (res.data.data.data.length == 0) {
						this.$message({
							type: "error",
							message: "暂无药师开具药方"
						})
					}
					this.prelist = res.data.data.data;
					console.log(this.prelist);
				});
			},
			submit() {
				if (this.radio == -1) {
					this.$message.error("请选择药店");
					return;
				}
				console.log(this.orderinfor);
				this.$axios.post("/api/goods_cart/prescriptionToCart", {
					shop_id: this.shoplist[this.radio].shop_id,
					token: this.$token,
					p_code: this.orderinfor.p_code,
				}).then(res => {
					console.log(res.status);
					if (res.status == 200) {
						this.radio = "-1",
							this.isdoctor = false;
						this.$message.success("添加购物车成功")
					} else {
						this.$message.error("添加购物车失败")
					}
				});
			},
		}
	};
</script>

<style lang="less" scoped>
	.shopboxlist {
		padding: 20px;
	
		.drugsbox {
			display: flex;
			border-bottom: 1px solid #CCCCCC;
			margin: 10px 0;
			padding: 10px 0;
	
			img {
				width: 80px;
				height: 80px;
			}
	
			.rightCot {
				margin-left: 30px;
	
				.price {
					margin-top: 30px;
					color: red;
				}
			}
		}
	}
	.choiceshop {
		position: fixed;
		width: 100%;
		height: 100%;
		top: 0;
		z-index: 999;
		background: rgba(0, 0, 0, 0.5);

		.shopbox {
			width: 600px;
			height: 502px;
			background-color: #fff;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);

			.title {
				text-align: center;
				line-height: 40px;
				font-size: 17px;
				border-bottom: 1px solid #EAEAEA;
				position: absolute;
				width: 100%;
				background-color: #fff;
				top: 0;
				left: 0;
				z-index: 99;

				.closebox {
					position: absolute;
					right: 20px;
					top: 10px;
					font-size: 20px;
					cursor: pointer;
				}

			}

			.choicecontent {
				height: 460px;
				width: 100%;
				padding-top: 40px;
				overflow: auto;
			}

			.choicebottom {
				.button {
					width: 100px;
					text-align: center;
					margin: 0 auto;
					padding: 5px 10px;
					margin-top: 4px;
					border-radius: 5px;
					border: 1px solid #FF0000;
					color: red;
				}
			}
		}
	}

	.ym {
		height: 50px;
		position: relative;
	}

	.el-pagination {
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		bottom: 20px;
	}

	.service {
		width: 1200px;
		margin: 0 auto;
		min-height: 967px;
		margin-top: 10px;
		font-size: 16px;
		background: #fff;
		border: 1px solid #ccc;
	}

	.service>p {
		font-size: 18px;
		color: #4eeec8;
		height: 70px;
		line-height: 70px;
		border-bottom: 1px solid #ccc;
		padding-left: 20px;
	}

	.explain {
		width: 1132px;
		margin: 15px auto 15px;
		background: #fcfcfc;

		.fr {
			margin-right: 40px;
			color: #4EEEC8;
			opacity: 0;
			cursor: pointer;
		}
	}

	.explain:hover .fr {
		opacity: 1;
	}

	.explain>p {
		height: 66px;
		line-height: 66px;
		color: #000000;
		background: #f6f6f6;
		padding-left: 30px;
	}

	.explain span:nth-child(2) {
		margin-left: 25px;
	}

	.wrapper {
		width: 700px;
		padding: 20px 0px;
		padding-left: 60px;
		border-bottom: 1px solid #fcfcfc;
	}

	.wrapper .title,
	.wrapper .content {
		display: flex;
		margin-bottom: 10px;
	}

	.wrapper .title div,
	.wrapper .content div {
		width: 200px;
	}

	.wrapper .title .good_name {
		width: 280px;
		padding-right: 20px;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
		overflow: hidden;
	}

	.wrapper .content .fangshi {
		width: 100%;
		padding-right: 20px;
		display: flex;
		justify-content: space-between;
	}
</style>
